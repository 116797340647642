import React from "react"
import { Link, graphql } from "gatsby"
import PropTypes from "prop-types"
import styled from "styled-components"

import { FaHome } from "react-icons/fa"
import Layout from "../components/layout"
import SEO from "../components/seo"
import AtractionItem from "../components/atractionItem"

const AtractionsWrapper = styled.section`
  .atractions-container {
    padding-top: 4rem;
    padding-bottom: 4rem;
    article {
      display: flex;
      flex-direction: column;
    }
    .header {
      text-align: center;
    }
  }
  .atraction-items-container {
    padding: 2rem 0;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    @media (max-width: 925px) {
      flex-direction: column;
      align-items: center;
    }
  }
`

const AtractionsPage = ({ data }) => {
  const { edges: atractionItems } = data.atractionsData
  return (
    <Layout>
      <SEO
        title={"Atrakcje"}
        description={"Atrakcje w okolicy naszego gospodarstwa"}
      />
      <AtractionsWrapper>
        <div className="container atractions-container">
          <article>
            <header>
              <div className="header">
                <h1>Okoliczne atrakcje</h1>
                <h2>...czyli co można zobaczyć i zwiedzić w okolicy</h2>
              </div>
            </header>
            <div className="atraction-items-container">
              {atractionItems.map(atraction => (
                <AtractionItem
                  key={atraction.node.id}
                  title={atraction.node.frontmatter.title}
                  slug={atraction.node.fields.slug}
                  image={
                    atraction.node.frontmatter.atractionImage.image
                      .childImageSharp.fluid
                  }
                  excerpt={atraction.node.excerpt}
                />
              ))}
            </div>
            <Link className="to-group-link" to="/">
              <FaHome style={{ color: "#ee7879" }} />
              <span style={{ marginLeft: "1rem" }}>Strona Główna</span>
            </Link>
          </article>
        </div>
      </AtractionsWrapper>
    </Layout>
  )
}

AtractionsPage.propTypes = {
  data: PropTypes.shape({
    allMarkdownRemark: PropTypes.shape({
      edges: PropTypes.array,
    }),
  }),
}

export const pageQuery = graphql`
  query AtractionsPageQuery {
    atractionsData: allMarkdownRemark(
      sort: { fields: [frontmatter___title], order: ASC }
      filter: { frontmatter: { templateKey: { eq: "atraction-page" } } }
    ) {
      edges {
        node {
          id
          excerpt(truncate: false, pruneLength: 190)
          html
          fields {
            slug
          }
          frontmatter {
            title
            templateKey
            atractionImage {
              image {
                id
                childImageSharp {
                  fluid(maxWidth: 960) {
                    ...GatsbyImageSharpFluid_withWebp
                  }
                }
              }
              imageAlt
            }
          }
        }
      }
    }
  }
`

export default AtractionsPage
