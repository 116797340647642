import React from "react"
import PropTypes from "prop-types"
import styled from "styled-components"
import Img from "gatsby-image"
import { Link } from "gatsby"

const AtractionItemWrapper = styled.section`
  max-width: 45%;
  margin: 1rem;
  .atraction {
    margin-bottom: 1rem;
  }
  .description {
    p {
      max-height: 25vh;
      text-align: justify;
    }
    a {
      float: right;
      padding: 0.3rem 0.6rem;
    }
  }
  @media (max-width: 925px) {
    max-width: 100%;
  }
`

const AtractionItem = ({ image, title, excerpt, slug }) => (
  <AtractionItemWrapper>
    <article>
      <div className="atraction">
        <figure>
          <Img style={{ height: "200px", borderRadius: "5px" }} fluid={image} />
        </figure>
        <header>
          <h2>{title}</h2>
        </header>
        <div className="description">
          <p>{excerpt}</p>
          <Link className="to-item-link" to={`/atrakcje${slug}`}>
            Więcej...
          </Link>
        </div>
      </div>
    </article>
  </AtractionItemWrapper>
)

AtractionItem.propTypes = {
  image: PropTypes.object,
  title: PropTypes.string,
  excerpt: PropTypes.string,
  slug: PropTypes.string,
}

export default AtractionItem
